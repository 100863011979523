import { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import { ShipOwnerDTO } from "../@types/ShipOwner";
import { VesselDTO } from "../@types/Vessel";
import { useKeycloak } from "@react-keycloak/web";
import { ShipOwnerVesselDto } from "../@types/ShipOwnerVessel";
import { useQuery } from "@apollo/client";
import { GET_SHIPOWNERS_QUERY, GET_VESSELS_BY_SHIPOWNER_QUERY } from "../graphQL/Queries";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
// ----------------------------------------------------------------------
type ShipOwnerAndVesselSelectsProps = {
  loadFromSessionShipOwnerAndVessels?: boolean;
  showVesselSelect?: boolean;
  selectedShipOwnerId: number | undefined;
  selectedVesselId: number | undefined;
  manualSetAndFindShipOwnerAndVessel: boolean;
  stateOfLoadingFind: boolean;
  setSelectedShipOwnerIdAndVesselId?: React.Dispatch<
    React.SetStateAction<ShipOwnerVesselDto | undefined>
  >;
  setAvailableShipOwnerVessels?: React.Dispatch<React.SetStateAction<VesselDTO[] | undefined>>;
  setAvailableShipOwnersForIhms?: React.Dispatch<React.SetStateAction<ShipOwnerDTO[]>>;
};

const SHIP_OWNER_VALUE_KEY = "SelectedShipOwnerStorage";
const VESSEL_VALUE_KEY = "SelectedVesselStorage";


// ----------------------------------------------------------------------

export default function ShipOwnerAndVesselSelects({
  loadFromSessionShipOwnerAndVessels,
  showVesselSelect,
  selectedShipOwnerId,
  selectedVesselId,
  setSelectedShipOwnerIdAndVesselId,
  setAvailableShipOwnerVessels,
  setAvailableShipOwnersForIhms,
  manualSetAndFindShipOwnerAndVessel,
  stateOfLoadingFind,
}: ShipOwnerAndVesselSelectsProps) {
  const { initialized, keycloak } = useKeycloak();
  const [userRole, setUserRole] = useState<undefined | string>(undefined);
  const [shipOwnerIsLoaded, setShipOwnerIsLoaded] = useState<boolean>(false);
  const [vesselIsLoaded, setVesselIsLoaded] = useState<boolean>(false);
  const [selectedVessel, setSelectedVessel] = useState<undefined | VesselDTO>(undefined);
  const [selectedShipOwner,setSelectedShipOwner] = useState<ShipOwnerDTO | undefined>(undefined);
  const [availableShipOwners, setAvailableShipOwners] = useState<ShipOwnerDTO[]>([]);
  const [availableShipOwnerVessels, setAvailableVessels] = useState<VesselDTO[] | undefined>(undefined);
  const [findSelectedShipOwnerIdAndVesselId, setFindSelectedShipOwnerIdAndVesselId]=useState<ShipOwnerVesselDto>();

  const getShipOwnersQuery = () =>
    useQuery(GET_SHIPOWNERS_QUERY, {
      variables: {
        limit: 0,
        page: 0,
        searchQuery: "",
      },
      onError(err) {
        toast.configure();
        toast.error(`Error: ${err.message}`);
      },
      fetchPolicy: "network-only",
    });

  const getVesselsQuery = (
    shipOwner: ShipOwnerDTO | undefined,
    limit: number,
    page: number,
    searchQuery: string
  ) =>
    useQuery(GET_VESSELS_BY_SHIPOWNER_QUERY, {
      variables: {
        shipOwnerId: shipOwner && shipOwner.id && shipOwner.id !== '0' ? shipOwner?.id : -1,
        limit: limit,
        page: page,
        searchQuery: searchQuery,
      },
      skip: shipOwner == undefined,
      onError(err) {
        toast.configure();
        toast.error(err.message);
      },
      fetchPolicy: "network-only",
    });


  const useGetShipOwnersQuery = getShipOwnersQuery();
  const useGetVesselsQuery = getVesselsQuery(selectedShipOwner, 0, 0, "");

  useEffect(() => {
    if (initialized) {
      if (keycloak.realmAccess) {
        if (keycloak.realmAccess.roles) {
          if (keycloak.realmAccess.roles.find((x) => x === "ShipOwner")) {
            setUserRole("ShipOwner");
          } else if (keycloak.realmAccess.roles.find((x) => x === "Supplier")) {
            setUserRole("Supplier");
          } else if (
            keycloak.realmAccess.roles.find((x) => x === "Maintainer")
          ) {
            setUserRole("Maintainer");
          }
        }
      } else if (window.sessionStorage.getItem("userRole")) {
        setUserRole(window.sessionStorage.getItem("userRole")?.toString());
      }
    }
  }, [keycloak, initialized]);

  useEffect(() => {
    if (useGetShipOwnersQuery.data) {
      setAvailableShipOwners(
        useGetShipOwnersQuery.data.fetchShipOwners.ShipOwners
      );
      if(setAvailableShipOwnersForIhms){
        
        setAvailableShipOwnersForIhms(useGetShipOwnersQuery.data.fetchShipOwners.ShipOwners)
      }
    }
  }, [useGetShipOwnersQuery]);

  useEffect(() => {
    if (useGetVesselsQuery.data) {
      setAvailableVessels(useGetVesselsQuery.data?.getVesselsByShipOwner);
      if(setAvailableShipOwnerVessels){
        setAvailableShipOwnerVessels(useGetVesselsQuery.data?.getVesselsByShipOwner);
      }
    }
  }, [useGetVesselsQuery]);

  useEffect(() => {
    if (loadFromSessionShipOwnerAndVessels) {
      let foundSelectedShipOwner = false;
      if (
        availableShipOwners != undefined &&
        availableShipOwners !== null &&
        availableShipOwners.length > 0
      ) {
        if (availableShipOwners.length == 1) {
          setSelectedShipOwner(availableShipOwners[0]);
          foundSelectedShipOwner = true;
        } else {
          const shipOwnerFromStorage =
            sessionStorage.getItem(SHIP_OWNER_VALUE_KEY);
          if (shipOwnerFromStorage) {
            const shipOwnerId = JSON.parse(shipOwnerFromStorage);
            if (shipOwnerId) {
              
              const findSelectedShipOwner = availableShipOwners.find(
                (x) => x.id === shipOwnerId
              );
              if (findSelectedShipOwner) {
                
                foundSelectedShipOwner = true;
                setSelectedShipOwner(findSelectedShipOwner);
              }else if(selectedShipOwner == undefined){
                
                setSelectedShipOwner({
                  id: '0', name: "All"
                });
              }else if (findSelectedShipOwner==undefined){
                setSelectedShipOwner({
                  id: '0', name: "All"
                });
              }
            }
          }else{
            setShipOwnerIsLoaded(true);
          }
        }
        if (!foundSelectedShipOwner && selectedShipOwnerId != undefined) {
          setSelectedShipOwner({
            id: selectedShipOwnerId.toString()
          });
        } else if (!foundSelectedShipOwner && selectedShipOwnerId == undefined) {
          setSelectedShipOwner({
            id: '0',
            name: 'all'
          });
        }
        setShipOwnerIsLoaded(true);
      }
      
    }

  }, [availableShipOwners]);

  useEffect(() => {
    if(shipOwnerIsLoaded == true){
      if (loadFromSessionShipOwnerAndVessels) {
        let shipOwnerFromStorageData = null;
        const shipOwnerFromStorage =sessionStorage.getItem(SHIP_OWNER_VALUE_KEY);
        if(shipOwnerFromStorage !== null){
          shipOwnerFromStorageData = JSON.parse(shipOwnerFromStorage);
        }
        if (
          availableShipOwnerVessels != undefined &&
          availableShipOwnerVessels !== null &&
          availableShipOwnerVessels.length > 0
        ) {
          if (selectedShipOwner != undefined && selectedShipOwner !== null) {
            let selectedVessel = {} as VesselDTO;
            if (availableShipOwnerVessels.length == 1) {
              selectedVessel = availableShipOwnerVessels[0];
            } else {
              const vesselFromStorage = sessionStorage.getItem(VESSEL_VALUE_KEY);
              if (vesselFromStorage) {
                const vessel = JSON.parse(vesselFromStorage);
                if (vessel) {
                  const findSelectedVessel = availableShipOwnerVessels.find(
                    (x) => x.id === vessel.id
                  );
                  if (findSelectedVessel) {
                    selectedVessel = findSelectedVessel;
                  }
                }
              }
            }
            if (selectedVessel != undefined && selectedVessel !== null) {
              setSelectedVessel(selectedVessel);
              sessionStorage.setItem(
                VESSEL_VALUE_KEY,
                JSON.stringify(selectedVessel)
              );
            } else if (selectedVesselId != undefined) {
              const selectedVessel = {} as VesselDTO;
              selectedVessel.id = selectedVesselId.toString();
              setSelectedVessel(selectedVessel);
              sessionStorage.setItem(
                VESSEL_VALUE_KEY,
                JSON.stringify(selectedVessel)
              );
            }else{
              setSelectedVessel({id: '0', name:'All'});
            }
          }else if (selectedShipOwner != undefined){
            setSelectedVessel({id: '0', name:'All'});
          }
          setVesselIsLoaded(true);
        }else if(shipOwnerFromStorageData !== null 
          && shipOwnerFromStorageData.id != undefined
          && shipOwnerFromStorageData.id === "0"){

          setVesselIsLoaded(true);
        }else if(availableShipOwnerVessels != undefined && availableShipOwnerVessels.length ==0 && shipOwnerFromStorageData!=undefined){
          setVesselIsLoaded(true);
        }
      }
    }
  }, [shipOwnerIsLoaded,availableShipOwnerVessels]);

  useEffect(() => {
    if(userRole && userRole !== "ShipOwner" && userRole !== "Supplier" && manualSetAndFindShipOwnerAndVessel){
      
        if (shipOwnerIsLoaded && vesselIsLoaded) {

      
          if (setFindSelectedShipOwnerIdAndVesselId) {
            
            if(selectedShipOwner == undefined && selectedVessel == undefined){
    
              setFindSelectedShipOwnerIdAndVesselId({
                shipOwnerDto: { id: '0', name: 'All' },
                vesselDto: { id: '0', name: 'All' }
              })
            }else if(selectedShipOwner && selectedVessel == undefined){
              
              setFindSelectedShipOwnerIdAndVesselId({
                shipOwnerDto: { id: selectedShipOwner.id, name: selectedShipOwner.name },
                vesselDto: { id: '0', name: 'All' }
              })
            }else if(selectedShipOwner && selectedVessel){
              
              if(selectedShipOwner.id && selectedVessel.id){
                setFindSelectedShipOwnerIdAndVesselId({
                  shipOwnerDto: { id: selectedShipOwner.id, name: selectedShipOwner.name },
                  vesselDto: { id: selectedVessel.id, name: selectedVessel.name }
                })
              }else if(selectedShipOwner.id && selectedVessel.id == undefined){
                setFindSelectedShipOwnerIdAndVesselId({
                  shipOwnerDto: { id: selectedShipOwner.id, name: selectedShipOwner.name },
                  vesselDto: { id: "0", name: "All" }
                })
              }
    
            }
          }
        }
      
    }else{
      if (shipOwnerIsLoaded && vesselIsLoaded) {

      
        if (setSelectedShipOwnerIdAndVesselId) {
          
          if(selectedShipOwner == undefined && selectedVessel == undefined){
  
            setSelectedShipOwnerIdAndVesselId({
              shipOwnerDto: { id: '0', name: 'All' },
              vesselDto: { id: '0', name: 'All' }
            })
          }else if(selectedShipOwner && selectedVessel == undefined){
            
            setSelectedShipOwnerIdAndVesselId({
              shipOwnerDto: { id: selectedShipOwner.id, name: selectedShipOwner.name },
              vesselDto: { id: '0', name: 'All' }
            })
          }else if(selectedShipOwner && selectedVessel){
            
            if(selectedShipOwner.id && selectedVessel.id){
              setSelectedShipOwnerIdAndVesselId({
                shipOwnerDto: { id: selectedShipOwner.id, name: selectedShipOwner.name },
                vesselDto: { id: selectedVessel.id, name: selectedVessel.name }
              })
            }else if(selectedShipOwner.id && selectedVessel.id == undefined){
              setSelectedShipOwnerIdAndVesselId({
                shipOwnerDto: { id: selectedShipOwner.id, name: selectedShipOwner.name },
                vesselDto: { id: "0", name: "All" }
              })
            }
  
          }
        }
      }
    }

  }, [shipOwnerIsLoaded, vesselIsLoaded]);

  
  
  const handleShipOwnerInput = (event: SelectChangeEvent) => {
    const selectedSOName = availableShipOwners?.filter(
      (soObject: ShipOwnerDTO) => soObject.id === (event.target.value as string)
    )[0];
    if (selectedSOName) {
      setSelectedShipOwner(selectedSOName);
      sessionStorage.setItem(
        SHIP_OWNER_VALUE_KEY,
        JSON.stringify(event.target.value as string)
      );
      if(userRole && userRole !== "ShipOwner" && userRole !== "Supplier" && manualSetAndFindShipOwnerAndVessel){
        if(setFindSelectedShipOwnerIdAndVesselId){
          setFindSelectedShipOwnerIdAndVesselId({
            shipOwnerDto: { id: selectedSOName.id, name: selectedSOName.name },
            vesselDto: { id: "0", name: "all" }
          })
        }
      }else{
        if(setSelectedShipOwnerIdAndVesselId){
          setSelectedShipOwnerIdAndVesselId({
            shipOwnerDto: { id: selectedSOName.id, name: selectedSOName.name },
            vesselDto: { id: "0", name: "all" }
          })
        }
      }
    } else if (event.target.value.trim() === 'all' && selectedShipOwnerId != undefined) {
      const selectedShipOwner = {} as ShipOwnerDTO;
      selectedShipOwner.id = selectedShipOwnerId.toString();
      setSelectedShipOwner(selectedShipOwner);
      sessionStorage.setItem(
        SHIP_OWNER_VALUE_KEY,
        JSON.stringify(parseInt(selectedShipOwner.id))
      );
      if(userRole && userRole !== "ShipOwner" && userRole !== "Supplier" && manualSetAndFindShipOwnerAndVessel){
        if (setFindSelectedShipOwnerIdAndVesselId) {
          setFindSelectedShipOwnerIdAndVesselId({
            shipOwnerDto: selectedShipOwner,
            vesselDto: { id: "0", name: "all" }
          })
        }
      }else{
        if (setSelectedShipOwnerIdAndVesselId) {
          setSelectedShipOwnerIdAndVesselId({
            shipOwnerDto: selectedShipOwner,
            vesselDto: { id: "0", name: "all" }
          })
        }
      }

    } else if (event.target.value.trim() === 'all') {
      const selectedShipOwner = {} as ShipOwnerDTO;
      selectedShipOwner.id = '0';
      selectedShipOwner.name = "All";
      setSelectedShipOwner(selectedShipOwner);
      sessionStorage.setItem(
        SHIP_OWNER_VALUE_KEY,
        JSON.stringify(parseInt(selectedShipOwner.id))
      );
      sessionStorage.setItem(
        VESSEL_VALUE_KEY,
        JSON.stringify({id: "0", name: "All"})
      );
      if(userRole && userRole !== "ShipOwner" && userRole !== "Supplier" && manualSetAndFindShipOwnerAndVessel){
        if (setFindSelectedShipOwnerIdAndVesselId) {
          setFindSelectedShipOwnerIdAndVesselId({
            shipOwnerDto: { id: "0" },
            vesselDto: { id: "0" }
          })
        }
      }else{
        if (setSelectedShipOwnerIdAndVesselId) {
          setSelectedShipOwnerIdAndVesselId({
            shipOwnerDto: { id: "0" },
            vesselDto: { id: "0" }
          })
        }
      }

    }
  };

  const findButtonSearch = ()=>{
    if(setSelectedShipOwnerIdAndVesselId){
      setSelectedShipOwnerIdAndVesselId({shipOwnerDto:findSelectedShipOwnerIdAndVesselId?.shipOwnerDto, vesselDto:findSelectedShipOwnerIdAndVesselId?.vesselDto})
    }
  }



  const handleVesselInput = (event: SelectChangeEvent) => {
    if (shipOwnerIsLoaded && vesselIsLoaded) {
      const updatedSelectedVessel = availableShipOwnerVessels?.filter(
        (vObject: VesselDTO) => vObject.id === (event.target.value as string)
      )[0];
      if (updatedSelectedVessel) {
        setSelectedVessel(updatedSelectedVessel);
        if(userRole && userRole !== "ShipOwner" && userRole !== "Supplier" && manualSetAndFindShipOwnerAndVessel){
          if (setFindSelectedShipOwnerIdAndVesselId) {
            setFindSelectedShipOwnerIdAndVesselId(
              {
                shipOwnerDto: selectedShipOwner,
                vesselDto: updatedSelectedVessel
              }
            );
          }
        }else{
          if (setSelectedShipOwnerIdAndVesselId) {
            setSelectedShipOwnerIdAndVesselId(
              {
                shipOwnerDto: selectedShipOwner,
                vesselDto: updatedSelectedVessel
              }
            );
          }
        }

        if (updatedSelectedVessel?.id) {
          sessionStorage.setItem(
            VESSEL_VALUE_KEY,
            JSON.stringify(updatedSelectedVessel)
          );
        }
      } else if (event.target.value.trim() === 'all' && selectedVesselId != undefined) {
        const selectedVessel = {} as VesselDTO;
        selectedVessel.id = selectedVesselId.toString();
        if(userRole && userRole !== "ShipOwner" && userRole !== "Supplier" && manualSetAndFindShipOwnerAndVessel){
          if(setFindSelectedShipOwnerIdAndVesselId){
            setFindSelectedShipOwnerIdAndVesselId(
              {
                shipOwnerDto: selectedShipOwner,
                vesselDto: selectedVessel
              }
            );
          }
        }else{
          if (setSelectedShipOwnerIdAndVesselId) {
            setSelectedShipOwnerIdAndVesselId(
              {
                shipOwnerDto: selectedShipOwner,
                vesselDto: selectedVessel
              }
            );
          }
        }


        setSelectedVessel(selectedVessel);
        sessionStorage.setItem(
          VESSEL_VALUE_KEY,
          JSON.stringify(selectedVessel)
        );
      } else if (event.target.value.trim() === 'all') {
        const selectedVessel = {} as VesselDTO;
        selectedVessel.id = '0';
        if(userRole && userRole !== "ShipOwner" && userRole !== "Supplier" && manualSetAndFindShipOwnerAndVessel){
          if (setFindSelectedShipOwnerIdAndVesselId) {
            setFindSelectedShipOwnerIdAndVesselId(
              {
                shipOwnerDto: selectedShipOwner,
                vesselDto: selectedVessel
              }
            );
          }
        }else{
          if (setSelectedShipOwnerIdAndVesselId) {
            setSelectedShipOwnerIdAndVesselId(
              {
                shipOwnerDto: selectedShipOwner,
                vesselDto: selectedVessel
              }
            );
          }
        }


        setSelectedVessel(selectedVessel);
        sessionStorage.setItem(
          VESSEL_VALUE_KEY,
          JSON.stringify(selectedVessel)
        );
      }
    }
  };

  return (
    <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 3, sm: 2 }}>
      {userRole && userRole !== "ShipOwner" && userRole !== "Supplier" ? (
        <FormControl variant="standard" fullWidth sx={{ marginBottom: 2 }}>
          <InputLabel
            id="demo-simple-select-label"
            sx={{ color: "text.primary" }}
          >
            Select Ship Owner
          </InputLabel>
          <Select
            sx={{ minWidth: "90px" }}
            variant="standard"
            labelId="select-ship-owner-label"
            id="select-ship-owner"
            label="Ship Owner"
            value={
              (selectedShipOwner != undefined && !(selectedShipOwner.id === '0')) ? selectedShipOwner.id : "all"
            }
            onChange={handleShipOwnerInput}
          >
            <MenuItem key={"all"} value={"all"}>
              {"all"}
            </MenuItem>
            {useGetShipOwnersQuery.loading ? (
              <LinearProgress
                color="primary"
                sx={{
                  minHeight: "5px",
                  mt: 0.1,
                }}
              />
            ) : (
              availableShipOwners.map(
                (shipOwnerObject: ShipOwnerDTO, index: number) => (
                  <MenuItem key={index} value={shipOwnerObject.id}>
                    {shipOwnerObject.name}
                  </MenuItem>
                )
              )
            )}
          </Select>
        </FormControl>
      ) : availableShipOwners.length > 1 && (userRole == "ShipOwner" || userRole == "Supplier") &&
      (<FormControl variant="standard" fullWidth sx={{ marginBottom: 2 }}>
        <InputLabel
          id="demo-simple-select-label"
          sx={{ color: "text.primary" }}
        >
          Select Ship Owner
        </InputLabel>
        <Select
          sx={{ minWidth: "90px" }}
          variant="standard"
          labelId="select-ship-owner-label"
          id="select-ship-owner"
          label="Ship Owner"
          value={
            (selectedShipOwner != undefined && !(selectedShipOwner.id === '0')) ? selectedShipOwner.id : "all"
          }
          onChange={handleShipOwnerInput}
        >
          <MenuItem key={"all"} value={"all"}>
            {"all"}
          </MenuItem>
          {useGetShipOwnersQuery.loading ? (
            <LinearProgress
              color="primary"
              sx={{
                minHeight: "5px",
                mt: 0.1,
              }}
            />
          ) : (
            availableShipOwners.map(
              (shipOwnerObject: ShipOwnerDTO, index: number) => (
                <MenuItem key={index} value={shipOwnerObject.id}>
                  {shipOwnerObject.name}
                </MenuItem>
              )
            )
          )}
        </Select>
      </FormControl>)}
      {showVesselSelect !== false && (
        <FormControl variant="standard" fullWidth sx={{ marginBottom: 2 }}>
          <InputLabel
            id="demo-simple-select-label"
            sx={{ color: "text.primary" }}
          >
            Select Vessel
          </InputLabel>
          <Select
            sx={{ minWidth: "90px" }}
            variant="standard"
            labelId="select-vessel-label"
            id="select-vessel"
            label="Vessel"
            value={(selectedVessel != undefined && !(selectedVessel.id != undefined && (selectedVessel.id === '0' || selectedVessel.id.length === 0))) ? selectedVessel.id : "all"}
            onChange={handleVesselInput}
          >
            <MenuItem key={"all"} value={"all"}>
              {"all"}
            </MenuItem>
            {useGetVesselsQuery.loading ? (
              <LinearProgress
                color="primary"
                sx={{
                  minHeight: "5px",
                  mt: 0.1,
                }}
              />
            ) : (
              availableShipOwnerVessels?.map(
                (vesselObject: VesselDTO, index: number) => (
                  <MenuItem key={index} value={vesselObject.id}>
                    {vesselObject.name}
                  </MenuItem>
                )
              )
            )}
          </Select>
        </FormControl>
      )}
      {userRole && userRole !== "ShipOwner" && userRole !== "Supplier" && manualSetAndFindShipOwnerAndVessel && (
        <Stack >
        <LoadingButton sx={{mt:2}} variant="contained" onClick={findButtonSearch} loading={stateOfLoadingFind}>
          Find
        </LoadingButton>
        </Stack>
      )}
    </Stack>
  );
}
