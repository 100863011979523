import moment from "moment";

// ----------------------------------------------------------------------

export function fDateTime(date: Date | string | number) {
  return moment(new Date(date)).format("dd MMM yyyy p");
}

export function fToNow(date: Date | string | number) {
  return moment(new Date(date), "YYYYMMDD").fromNow();
}

export function parseDate(stringDate: string) {
  const date = new Date(stringDate);
  const timeDiff = date.getTimezoneOffset() - 1;
  const newDate = new Date(date.getTime() - timeDiff * 60000);

  return newDate.toLocaleDateString("el-GR");
  // return moment(new Date(newDate), "YYYYMMDD").toISOString().slice(0, 10);
}

export function parseDates(stringDate: string) {
  return moment(new Date(stringDate)).format("YYYY/MM/DD");
}

export function parseDateAndTime(stringDate: string) {
  const date = new Date(stringDate);
  const timeDiff = date.getTimezoneOffset() - 1;
  const newDate = new Date(date.getTime());
  return moment(newDate).format('DD/MM/YYYY HH:mm:ss');
}