import { Box, Button, Checkbox, Container, FormControl, FormControlLabel, FormGroup, InputLabel, LinearProgress, MenuItem, Select, SelectChangeEvent, Stack, Tooltip } from "@mui/material";
import Page from "../../Page";
import HeaderBreadcrumbs from "../../HeaderBreadcrumbs";
import { Icon } from "@iconify/react";
import arrowCircleLeftOutline from "@iconify/icons-eva/arrow-circle-left-outline";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router";
import { ShipOwnerDTO } from "../../../@types/ShipOwner";
import { useCallback, useEffect, useState } from "react";
import { GET_DANAOS_BATCHES, GET_SHIPOWNERS_QUERY } from "../../../graphQL/Queries";
import { useLazyQuery, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { DataGridPro, gridClasses, GridColDef, GridFilterModel, GridFilterPanel, GridRowClassNameParams, GridRowId, GridRowModel, GridRowSelectionModel, GridSortModel, GridToolbar, useGridApiRef } from "@mui/x-data-grid-pro";
import { width } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { DanaosBatchesExtended } from "../../../@types/Danaos";
import React from "react";
import { FilterTypeDTO, OperationValue } from "../../../@types/DataGrid";
import DanaosLogsOfDanaosBatchModal from "../../modal/DanaosLogsOfDanaosBatchModal";
import { parseDate, parseDateAndTime } from "../../../utils/formatTime";
import axios from "axios";
import { useSnackbar } from "notistack";

const serverUri = process.env.REACT_APP_SERVER_URI;
const useStyles = makeStyles(() => ({
  navigationIcon: {
    cursor: "pointer",
    color: "#0079C1",
    transition: "0.5s ease",
    "&:hover": {
      color: "#39464f",
      transition: "0.5s ease",
    },
  },
  customStyle: {
    "& .MuiDataGrid-toolbarContainer": {
      background: "#ffffff",
    },
  },
  uploadbtn: {
    "&.MuiLoadingButton-root": {
      backgroundColor: "transparent",
      boxShadow: "none",
      minWidth: "unset",
      padding: "0",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  },
}));



const useFetchShipOwnersQuery = () =>
  useQuery(GET_SHIPOWNERS_QUERY, {
    variables: {
      limit: 0,
      page: 1,
      searchQuery: "",
    },
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });


const SHIP_OWNER_VALUE_KEY = "SelectedShipOwnerStorage";
const DanaosBatches = () => {

  const classesNew = useStyles();
  const navigate = useNavigate();
  const apiRef = useGridApiRef();
  const { enqueueSnackbar } = useSnackbar();
  const token = window.sessionStorage.getItem("jwt");
  const [tableHeadOrdered, setTableHeadOrdered] = useState<GridColDef[]>([]);
  const [danaosLogsOfDanaosBatchModalOpen, setDanaosLogsOfDanaosBatchModalOpen] = useState<boolean>(false);
  const [availableShipOwners, setAvailableShipOwners] = useState<ShipOwnerDTO[]>([]);
  const [selectedShipOwner, setSelectedShipOwner] = useState<ShipOwnerDTO>();
  const [dataTableRows, setDataTableRows] = useState<GridRowModel[]>([]);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [isUnProcessed, setIsUnProcessed] = useState<boolean>(true);
  const [selectedDataTableRow, setSelectedDataTableRow] = useState<
    GridRowModel | undefined
  >(undefined);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  // datagrid filtering
  const [filtersArray, setFiltersArray] = useState<FilterTypeDTO[]>([]);
  const [refreshStateOfColumns, setRefreshStateOfColumns] = useState<boolean | undefined>(undefined);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
  // dataGrid sorting
  const [sortField, setSortField] = useState<string>("endRequestDate");
  const [sortOrder, setSortOrder] = useState<string>("desc");
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 20,
  });
  const [filtersPro, setFiltersPro] = React.useState<GridFilterModel>({
    items: [
      {
        field: "errorMessage",
        operator: "contains"
      }
    ]
  });
  const [orderedColumns, setOrderedColumns] = useState<GridColDef[]>([]);
  const [batchId, setBatchId] = useState<number>(0);
  const [sortingPro, setSortingPro] = React.useState<GridSortModel>(
    [{ "field": "endRequestDate", "sort": "desc" }]
  );
  const getShipOwnersQuery = useFetchShipOwnersQuery();



  const [getDanaosBatchesLazyQuery, { loading: danaosBatchesLoading, data: danaosBatchesData }] = useLazyQuery(
    GET_DANAOS_BATCHES,
    {
      variables: {
        limit: rowsPerPage,
        page: page,
        shipOwnerId: selectedShipOwner && selectedShipOwner.id ? parseInt(selectedShipOwner.id) : -1,
        filtersArray: filtersArray,
        sortingOrder: {
          field: sortField,
          sort: sortOrder,
        },
        isUnProcessed: isUnProcessed
      }
    }
  );

  const handleShipOwnerInput = (event: SelectChangeEvent) => {
    setSelectedShipOwner(
      availableShipOwners.filter(
        (soObject: ShipOwnerDTO) =>
          soObject.id === (event.target.value as string)
      )[0]
    );

  };

  const renderOptions = (id: number) => {
    return (
      <>
        <LoadingButton
          sx={{ ml: 1 }}
          variant="contained"
          className={classesNew.uploadbtn}
          onClick={() => {
            setBatchId(id);
            setDanaosLogsOfDanaosBatchModalOpen(true);
          }}
        >
          <Tooltip title="View">
            <VisibilityIcon sx={{ color: "#0079C1" }} />
          </Tooltip>
        </LoadingButton>

        <LoadingButton
          variant="contained"
          onClick={() => handleDownloadBatchRows(id)}

        >
          Download Batch
        </LoadingButton>
      </>
    );
  }

  useEffect(() => {
    getDanaosBatchesLazyQuery();
  }, [selectedShipOwner]);


  React.useEffect(() => {
    try {

      const TABLE_COLUMNS = [
        {
          field: "options",
          headerName: "Actions",
          width: 180,
          filterable: false,
          sortable: false,
          disableColumnMenu: true,
          renderCell: (params: any) => {

            return renderOptions(params.row.id);
          },
        },
        {
          field: "requestStartDate",
          headerName: "Request Start Date",
          width: 230,
          type: "string",
          filterable: false,
        },
        {
          field: "endRequestDate",
          headerName: "End Request Date",
          width: 230,
          type: "string",
          filterable: false,
        },
        {
          field: "errorMessage",
          headerName: "Error Message",
          width: 230,
          type: "boolean",
          filterable: true,
        },
        {
          field: "urlRequest",
          headerName: "Url Request",
          width: 230,
          type: "string",
          filterable: true,
        },
        {
          field: "shipOwnerName",
          headerName: "Shipowner",
          width: 230,
          type: "string",
          filterable: true,
        },
        {
          field: "totalDanaosRowsOfBatch",
          headerName: "Total Rows",
          width: 230,
          type: "string",
          filterable: false,
          sortable: false,
        },
      ];
      const savedData = localStorage.getItem("gridStateOfDanaosBatchesTable");

      if (savedData) {

        const savedDataJsonFormated = JSON.parse(savedData != undefined ? savedData : "{}");
        setOrderedColumns([]);


        if (TABLE_COLUMNS != undefined) {

          for (const field of savedDataJsonFormated.columns.orderedFields) {

            if (field != "__check__") {

              const savedColumn = TABLE_COLUMNS.find((obj) => {
                return obj.field === field;
              });
              if (savedColumn != undefined) {
                savedColumn.width = savedDataJsonFormated.columns.dimensions[field]["width"];
                if (field == "options" && dataTableRows.length > 0) {
                  savedColumn.renderCell =
                    (params: any) => {
                      return renderOptions(params.row.id);
                    };

                }
              }

              orderedColumns.push(savedColumn != undefined ? savedColumn : JSON.parse('{}'));
            }

          }

          setTableHeadOrdered(orderedColumns);


        }
      } else {
        setTableHeadOrdered(TABLE_COLUMNS);
      }

    } catch (error) {
      console.log(error)
    }
  }, [apiRef]);



  useEffect(() => {
    if (getShipOwnersQuery.data) {

      const shipOwnersArray: ShipOwnerDTO[] = [];
      interface ShipOwnerDTO {
        id?: any;
        name?: any;
      }
      getShipOwnersQuery.data?.fetchShipOwners.ShipOwners.forEach((row: any) => {
        shipOwnersArray.push(row);
      });
      shipOwnersArray.push({ id: 0, name: 'All' });
      setAvailableShipOwners(
        shipOwnersArray
      );
      setSelectedShipOwner(shipOwnersArray[0]);
    }
  }, [getShipOwnersQuery.data]);


  useEffect(() => {
    if (
      availableShipOwners != undefined &&
      availableShipOwners !== null &&
      availableShipOwners.length > 0
    ) {
      if (availableShipOwners.length == 1) {
        setSelectedShipOwner(availableShipOwners[0]);
      } else {
        const shipOwnerFromStorage =
          sessionStorage.getItem(SHIP_OWNER_VALUE_KEY);
        if (shipOwnerFromStorage) {
          const shipOwnerId = JSON.parse(shipOwnerFromStorage);
          if (shipOwnerId) {
            const findSelectedShipOwner = availableShipOwners.find(
              (x) => x.id === shipOwnerId
            );
            if (findSelectedShipOwner) {
              setSelectedShipOwner(findSelectedShipOwner);
            }
          }
        }
      }
    }
  }, [availableShipOwners]);


  useEffect(() => {
    if (
      danaosBatchesData &&
      danaosBatchesData.getDanaosBatchesAndShipowners
    ) {
      const rowsArrayfill: DanaosBatchesExtended[] = [];
      if (danaosBatchesData.getDanaosBatchesAndShipowners.rows) {
        for (const row of danaosBatchesData.getDanaosBatchesAndShipowners.rows) {
          rowsArrayfill.push({
            id: row.id,
            endRequestDate: typeof row.endRequestDate === "string" &&
              parseDateAndTime(row.endRequestDate) !== "Invalid Date"
              ? parseDateAndTime(row.endRequestDate)
              : row.endRequestDate,
            errorMessage: row.errorMessage,
            requestStartDate: typeof row.requestStartDate === "string" &&
              parseDateAndTime(row.requestStartDate) !== "Invalid Date"
              ? parseDateAndTime(row.requestStartDate)
              : row.requestStartDate,
            shipOwnerName: row.shipOwnerName,
            totalDanaosRowsOfBatch: row.totalDanaosRowsOfBatch,
            urlRequest: row.urlRequest,

          });
        }
        setDataTableRows(rowsArrayfill);
        setTotalRows(danaosBatchesData.getDanaosBatchesAndShipowners.totalRows);
      }
    }
  }, [danaosBatchesData]);

  useEffect(() => {
    const { page, pageSize } = paginationModel;
    setPage(page);
    setRowsPerPage(pageSize);

  }, [paginationModel]);

  const exportState = React.useCallback(() => {
    setRefreshStateOfColumns(undefined);

    if (apiRef) {
      if (apiRef.current) {
        const state = apiRef.current.exportState();
        if (state) {

          if (window.localStorage) {
            localStorage.setItem("gridStateOfDanaosBatchesTable", JSON.stringify(state));
          }

          setRefreshStateOfColumns(true);
        }
      }

    }

  }, [apiRef]);

  const downloadBatchExcelFileQuery = async (

    batchId: number,
    token: string | null
  ) => {
    return axios.get(
      `${serverUri}/api/v1/shipowners/danaos/batchId/${batchId}/vendor/all`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      }
    )
  };


  const handleDownloadBatchRows = async (batchId: number) => {
    if (batchId != undefined) {
      const response = downloadBatchExcelFileQuery(batchId, token);
      response.then((dt: any) => {
        const url = window.URL.createObjectURL(new Blob([dt.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' }));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "ordersOfBatch.xlsx"); //or any other extension 
        document.body.appendChild(link);
        link.click();
        enqueueSnackbar(`Download Successfull`, { variant: "success" });
        getDanaosBatchesLazyQuery();
      });

    }
  }



  // sorting handler
  const handleSortModelChange = (newModel: GridSortModel) => {
    setSortingPro(newModel);
    if (
      newModel !== null &&
      newModel != undefined &&
      newModel.length == 1 &&
      newModel[0].sort &&
      newModel[0].field
    ) {
      setSortField(newModel[0].field);
      setSortOrder(newModel[0].sort?.toString());
    }
  };
  // filtering handler
  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    const properFilterArray: FilterTypeDTO[] = [];
    setFiltersPro(filterModel);
    filterModel.items.forEach((filterObject) => {
      if (
        filterObject.field != undefined &&
        filterObject.operator != undefined &&
        filterObject.value != undefined
      ) {
        let opVal = OperationValue.UNKNOWN;
        switch (filterObject.operator) {
          case "contains":
            opVal = OperationValue.CONTAINS;
            break;
          case "equals":
            opVal = OperationValue.EQUALS;
            break;
          case "startsWith":
            opVal = OperationValue.STARTS_WITH;
            break;
          case "endsWith":
            opVal = OperationValue.ENDS_WITH;
            break;
          case "isEmpty":
            opVal = OperationValue.IS_EMPTY;
            break;
          case "isNotEmpty":
            opVal = OperationValue.IS_NOT_EMPTY;
            break;
          default:
            break;
        }
        const properFilter: FilterTypeDTO = {
          columnField: filterObject.field,
          operatorValue: opVal,
          value: filterObject.value,
        };
        properFilterArray.push(properFilter);
      }
    });
    if (properFilterArray.length > 0) {
      setFiltersArray(properFilterArray);
    }
  }, []);



  const clearFiltersAndSorting = () => {
    setFiltersArray([]);
    setSortField("endRequestDate");
    setSortOrder("desc");
    setFiltersPro({

      items: [
        {
          field: "errorMessage",
          operator: "contains"

        }
      ]
    });
    setSortingPro([{ "field": "endRequestDate", "sort": "desc" }]);
  }

  const handleUnProcessed = () => {
    setIsUnProcessed(!isUnProcessed);
  }


  return (
    <Page title="Purchase Order From Danaos Batches">

      <Container maxWidth={"xl"} sx={{ p: 0 }}>
        <Container maxWidth={"xl"} sx={{ p: 0 }}>
          <Stack
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <HeaderBreadcrumbs
              heading="Manage Orders"
              links={
                [
                  { name: "Dashboard" },
                  { name: "Purchased Orders" },
                  { name: "Danaos" },
                ]
              }
            />
            <Icon
              width={45}
              height={45}
              icon={arrowCircleLeftOutline}
              className={classesNew.navigationIcon}
              onClick={() => navigate("/dashboard/purchasedOrders/list")}
            />
          </Stack>

          <div>
            <Box sx={{ width: "100%", marginTop: 2, marginBottom: 2 }}>
              <FormControl variant="standard" fullWidth>
                <InputLabel
                  id="demo-simple-select-label"
                  sx={{ color: "text.primary" }}
                >
                  Select Ship Owner
                </InputLabel>
                <Select
                  variant="standard"
                  labelId="select-ship-owner-label"
                  id="select-ship-owner"
                  label="Ship Owners"

                  value={
                    selectedShipOwner != undefined ? selectedShipOwner.id : ""
                  }
                  onChange={handleShipOwnerInput}
                >
                  {getShipOwnersQuery.loading ? (
                    <LinearProgress
                      color="primary"
                      sx={{
                        minHeight: "5px",
                        mt: 0.1,
                      }}
                    />
                  ) : (
                    availableShipOwners?.map(
                      (shipOwnerObject: ShipOwnerDTO, index: number) => (
                        <MenuItem key={index} value={shipOwnerObject.id}>
                          {shipOwnerObject.name}
                        </MenuItem>
                      )
                    )
                  )}
                </Select>

              </FormControl>
              <Box sx={{ display: "flex", flexDirection: "row", ml: 1, mt: 2 }}>
                <Button sx={{ m: "auto 0" }} variant="contained" onClick={() => clearFiltersAndSorting()}>Clear Filters</Button>
                <FormGroup sx={{ flexDirection: "row", ml: 1 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isUnProcessed}
                        onChange={() =>
                          handleUnProcessed()
                        }
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Unprocessed"
                  />
                </FormGroup>

              </Box>
              <Box
                sx={{
                  height: 550,
                  width: 1,
                  "& .super-app-theme--2": {
                    bgcolor: () => "rgba(91, 229, 132, 0.15)",
                  },
                  "& .super-app-theme--1": {
                    bgcolor: () => "rgba(255, 244, 165, 0.5)",
                  },
                  [`.${gridClasses.row}.cold`]: {
                    backgroundColor: '#b9d5ff91',
                    color: '#1a3e72',
                  },
                  [`.${gridClasses.row}.hot`]: {
                    backgroundColor: '#ff943975',
                    color: '#1a3e72',
                    ":hover": {
                      backgroundColor: '#ff943975'
                    }
                  },

                }}
              >
                <DataGridPro
                  columns={tableHeadOrdered}
                  rows={dataTableRows}
                  rowCount={totalRows}
                  disableRowSelectionOnClick

                  components={{
                    Toolbar: GridToolbar,
                    FilterPanel: GridFilterPanel,
                  }}

                  getRowClassName={(params: GridRowClassNameParams<any>) => {
                    if (params.row.errorMessage) {
                      return 'hot'
                    } else {
                      return ''

                    }

                  }}

                  checkboxSelection
                  apiRef={apiRef}

                  className={classesNew.customStyle}
                  // // selection props
                  onRowSelectionModelChange={(newSelectionModel: GridRowId[]) => {
                    setSelectionModel(newSelectionModel);
                    if (newSelectionModel.length === 1) {
                      const selectedRow = +newSelectionModel[0];
                      setSelectedDataTableRow(dataTableRows[selectedRow]);
                    } else {
                      setSelectedDataTableRow(undefined);
                    }
                  }}
                  rowSelectionModel={selectionModel}
                  // filtering props
                  filterMode="server"
                  filterModel={filtersPro}
                  onFilterModelChange={onFilterChange}
                  // sorting props
                  sortingMode="server"
                  sortModel={sortingPro}
                  onSortModelChange={handleSortModelChange}
                  // pagination props
                  pagination
                  paginationMode="server"

                  pageSizeOptions={[20, 50, 100]}
                  paginationModel={{ pageSize: rowsPerPage, page: page }}
                  onPaginationModelChange={setPaginationModel}
                  onColumnVisibilityModelChange={exportState}
                  onColumnOrderChange={exportState}
                  onColumnWidthChange={exportState}

                  // onPageChange={(pg) => setpageNotProcessed(pg)}
                  loading={danaosBatchesLoading}
                />
              </Box>
            </Box>
            <DanaosLogsOfDanaosBatchModal
              isOpenPreview={danaosLogsOfDanaosBatchModalOpen}
              onClosePreview={() => setDanaosLogsOfDanaosBatchModalOpen(false)}
              batchId={batchId}
            />
          </div>
        </Container>

      </Container>
    </Page>
  );
}

export default DanaosBatches;